<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading dependants
  </loading-spinner>
  <dependant-details
    v-else-if="currentDependant"
    :currentDependant="currentDependant"
    @update="updateDependant"
    @close="viewDependant"
  />
  <div v-else>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search by name"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
    </div>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <span class="badge bg-dark py-2 fw-normal">
        Total Dependants: {{ dependants.length }}
      </span>
      <div class="d-flex align-items-center small fst-italic">
        <span
          class="bg-danger border border-light rounded-circle me-2"
          style="width: 10px; height: 10px"
        ></span>
        new assessment
      </div>
    </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-3">
      <div class="col-12" v-if="computedDependants.length == 0">
        <div
          class="text-center bg-white border py-3 shadow-sm fst-italic text-muted small rounded"
        >
          No dependant found
        </div>
      </div>
      <div
        class="col"
        v-for="dependant in computedDependants"
        :key="dependant._id"
        :ref="`dependant_${dependant._id}`"
      >
        <div class="card h-100 shadow-sm">
          <div class="card-body d-flex flex-column bg-icons p-0">
            <!-- <div
              class="
                navbar-brand
                m-0
                p-3
                rounded
                shadow-sm
                border border-white
                bg-white
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <img
                src="@/assets/text.png"
                class="logo-text me-2"
                style="height: 12px"
              />
              <span class="text-center fs-6 fw-medium">STUDENT</span>
            </div> -->
            <!-- <div class="px-3 py-5">
              <div
                class="
                  ratio ratio-1x1
                  mx-auto
                  w-50
                  bg-white
                  rounded-circle
                  border border-5 border-white
                  shadow
                  overflow-hidden
                "
                style="max-width: 120px"
              >
                <img
                  src="@/assets/images/user-placeholder.png"
                  class="w-100 h-100"
                  style="object-fit: cover"
                />
              </div>
            </div> -->
            <div
              class="student-card-name fw-medium bg-blue-dark text-light py-2 px-5"
            >
              <p class="mb-0">{{ dependant.name }}</p>
            </div>
          </div>
          <div
            class="card-footer bg-transparent pt-4 pb-0 px-0 text-nowrap border-0"
          >
            <p class="text-center">
              <span class="badge bg-primary px-4 py-2 fs-6">
                {{ dependant.id_no }}
              </span>
            </p>
            <div
              class="mx-auto d-flex justify-content-between align-items-center px-3 mb-1"
              style="max-width: 170px"
            >
              <span class="text-muted fw-medium">DOB</span>
              <span>
                {{ Helper.formatDate(dependant.date_of_birth) }}
              </span>
            </div>
            <div
              class="mx-auto d-flex justify-content-between align-items-center px-3 mb-2"
              style="max-width: 170px"
            >
              <span class="text-muted fw-medium">Age</span>
              <span>
                {{ Helper.getAge(dependant.date_of_birth, true) }}
              </span>
            </div>
            <div
              class="mx-auto d-flex justify-content-between align-items-center px-3 mb-2 clickable"
              @click="viewDependant(dependant._id)"
              style="max-width: 170px"
            >
              <span class="text-muted fw-medium">Class</span>
              <span class="badge bg-light border text-dark fw-normal">
                {{ dependant.classes ? dependant.classes.length : 0 }}
              </span>
            </div>
            <div
              class="mx-auto d-flex justify-content-between align-items-center px-3 mb-2 clickable"
              @click="viewDependant(dependant._id, 'assessments')"
              style="max-width: 170px"
            >
              <span class="text-muted fw-medium">Assessment</span>
              <span
                class="badge bg-light border text-dark fw-normal position-relative"
              >
                {{
                  dependant.classes
                    ? dependantAssessmentCount(dependant.classes)
                    : 0
                }}
                <span
                  class="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle"
                  style="width: 7px; height: 7px"
                  v-if="
                    dependant.classes
                      ? dependantAssessmentCount(dependant.classes) !==
                        (dependant.dependant_assessments
                          ? dependant.dependant_assessments.length
                          : 0)
                      : false
                  "
                >
                </span>
              </span>
            </div>
            <div
              class="mx-auto d-flex justify-content-between align-items-center px-3 mb-2 clickable"
              style="max-width: 170px"
              @click="viewDependant(dependant._id, 'report-cards')"
            >
              <span class="text-muted fw-medium">Report Card</span>
              <span class="badge bg-light border text-dark fw-normal">
                {{
                  dependant.dependant_report_cards
                    ? dependant.dependant_report_cards.length
                    : 0
                }}
              </span>
            </div>
            <div
              class="mx-auto d-flex justify-content-between align-items-center px-3 clickable"
              style="max-width: 170px"
              @click="viewDependant(dependant._id, 'certificates')"
            >
              <span class="text-muted fw-medium">Certificates</span>
              <span class="badge bg-light border text-dark fw-normal">
                {{
                  dependant.dependant_certificates
                    ? dependant.dependant_certificates.length
                    : 0
                }}
              </span>
            </div>
            <div class="pt-4">
              <button
                class="btn btn-sm bg-cyan py-2 fw-medium w-100 rounded-0"
                @click="viewDependant(dependant._id)"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import DependantDetails from "@/components/DependantDetails";

export default {
  components: { LoadingSpinner, DependantDetails },
  data() {
    return {
      isLoading: false,
      search: "",
      dependants: [],
      currentDependant: null,
    };
  },
  watch: {
    "$route.params": {
      handler() {
        this.handleParamChange();
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    computedDependants() {
      const search = this.search.toLowerCase();

      return this.dependants.filter((dependant) =>
        dependant.name.toLowerCase().includes(search)
      );
    },
    dependantAssessmentCount() {
      return (dependantClasses) => {
        return dependantClasses
          .map(
            (cls) =>
              cls.assessments.filter((ca) => ca.status == "Published").length
          )
          .reduce((total, num) => total + num, 0);
      };
    },
  },
  methods: {
    updateDependant(e) {
      let dependant = this.dependants.find((d) => d._id == e._id);
      let dependantIndex = this.dependants.findIndex((d) => d._id == e._id);

      if (dependant) {
        this.$set(this.dependants, dependantIndex, e);

        this.handleParamChange();
      }
    },
    viewDependant(dependantId, view) {
      let route = {
        name: this.$route.name,
        params: {
          id: dependantId,
        },
        query: {},
      };

      if (view) {
        route.query["view"] = view;
      }

      this.$router.push(route);
    },
    handleParamChange() {
      if (this.$route.params.id) {
        this.currentDependant = this.dependants.find(
          (dependant) => dependant._id == this.$route.params.id
        );
      } else {
        this.currentDependant = null;
      }
    },
    async getDependants() {
      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `dependants?_limit=-1&${
              this.$store.state.user.isStudent ? "account" : "user"
            }=${this.$store.state.user._id}&status_in=Active&status_in=Inactive`
          );

          this.dependants = data;

          if (data.length > 0) {
            if (data.some((d) => d.classes && d.classes.length > 0)) {
              await this.getClasses(
                this.Helper.removeDuplicates(
                  data.map((d) => d.classes.map((c) => c._id)).flat()
                )
              );
            }

            if (this.$store.state.user.isStudent) {
              this.$route.params.id = data[0]._id;
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          resolve();
        }
      });
    },
    async getClasses(classesId) {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          let url = "classes?_limit=-1";

          classesId.forEach((id) => {
            url += `&id_in=${id}`;
          });

          const { data } = await this.API.get(url);

          if (data && data.length > 0) {
            this.dependants = this.dependants.map((dependant) => {
              dependant.classes = dependant.classes
                .filter((cls) => cls.status != "Deleted")
                .map((cls) => {
                  let _class = data.find((d) => d._id == cls._id);

                  if (_class) {
                    cls = _class;
                  }

                  return cls;
                });

              return dependant;
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  async mounted() {
    let waitUserAvailable = async () => {
      this.isLoading = true;

      if (this.$store.state.user) {
        await this.getDependants();

        this.isLoading = false;

        this.handleParamChange();
      } else {
        setTimeout(() => {
          waitUserAvailable();
        }, 1000);
      }
    };

    waitUserAvailable();
  },
};
</script>