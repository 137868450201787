<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading dependant details
  </loading-spinner>
  <assessment
    v-else-if="
      !isLoading &&
      currentView &&
      !['details', 'report-cards', 'assessments', 'certificates'].includes(
        currentView
      )
    "
    :assessmentId="currentView"
    :dependantId="currentDependant._id"
    @close="closeAssessment"
  />
  <div
    v-else-if="
      !isLoading &&
      currentView &&
      ['details', 'report-cards', 'assessments', 'certificates'].includes(
        currentView
      )
    "
  >
    <div
      class="p-3 bg-blue-dark text-light rounded shadow-sm border d-flex align-items-center"
    >
      <button
        class="btn btn-sm py-0 me-3 lh-1"
        @click="$emit('close')"
        v-if="!$store.state.user.isStudent"
      >
        <i class="fas fa-arrow-left text-light" />
      </button>
      <p class="mb-0 text-truncate fw-medium lh-1" v-if="currentDependant">
        {{ currentDependant.name }}
      </p>
      <span class="text-muted fst-italic" v-else>No dependant selected</span>
    </div>
    <div
      class="py-3 text-nowrap overflow-auto no-scrollbar"
      v-if="currentDependant"
    >
      <button
        class="btn border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable"
        :class="
          $route.query && !$route.query.view
            ? 'text-primary bg-white'
            : 'text-muted'
        "
        @click="changeView('details')"
      >
        <small class="fw-medium">Classes</small>
      </button>
      <button
        class="btn ms-2 border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable"
        :class="
          $route.query && $route.query.view == 'assessments'
            ? 'text-primary bg-white'
            : 'text-muted'
        "
        @click="changeView('assessments')"
      >
        <small class="fw-medium">Assessments</small>
      </button>
      <button
        class="btn ms-2 border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable"
        :class="
          $route.query && $route.query.view == 'report-cards'
            ? 'text-primary bg-white'
            : 'text-muted'
        "
        @click="changeView('report-cards')"
      >
        <small class="fw-medium">Report Cards</small>
      </button>
      <button
        class="btn ms-2 border ps-2 py-1 pe-2 rounded shadow-sm overflow-hidden text-nowrap clickable"
        :class="
          $route.query && $route.query.view == 'certificates'
            ? 'text-primary bg-white'
            : 'text-muted'
        "
        @click="changeView('certificates')"
      >
        <small class="fw-medium">Certificates</small>
      </button>
    </div>
    <template v-if="currentView == 'details'">
      <div
        class="text-center bg-white border py-3 shadow-sm fst-italic text-muted small rounded"
        v-if="!currentDependant.classes || currentDependant.classes.length == 0"
      >
        No class available
      </div>
      <div class="row g-3 mx-0">
        <div
          class="col-12 d-flex flex-column flex-sm-row bg-white p-3 border rounded shadow-sm"
          v-for="cls in currentDependant.classes"
          :key="cls._id"
          :id="cls._id"
        >
          <div
            class="flex-shrink-0 ratio ratio-1x1 bg-light border rounded mb-4 mx-auto"
            style="width: 100px; height: 100px"
          >
            <img
              :src="Helper.formatMediaUrl(cls.poster)"
              class="shadow-sm rounded clickable"
              v-if="Helper.formatMediaUrl(cls.poster)"
              @click="currentMedia = cls.poster"
            />
            <div
              class="small text-muted d-flex justify-content-center align-items-center"
              v-else
            >
              No image
            </div>
          </div>
          <div class="flex-grow-1 ms-0 ms-sm-3">
            <div
              class="d-flex justify-content-between align-items-center flex-column flex-sm-row"
            >
              <p class="fw-medium lh-sm mb-2 mb-sm-0 me-0 me-sm-2">
                {{ Helper.removeBreakLine(cls.name) }}
              </p>
              <div
                class="badge py-2 px-3"
                :class="`bg-${Helper.getLevelColor(cls.level)}`"
                v-if="cls.level"
              >
                {{ cls.level }}
              </div>
            </div>
            <hr class="my-4" />
            <p class="small fw-medium text-primary">Resources</p>
            <div
              class="bg-white border rounded shadow-sm p-3 text-muted fst-italic small"
              v-if="classResources(cls._id).length == 0"
            >
              No resources yet
            </div>
            <div class="list-group" v-else>
              <div
                class="list-group-item pe-0"
                v-for="resource in classResources(cls._id)"
                :key="`resource-${resource._id}`"
              >
                <div
                  class="d-flex flex-nowrap justify-content-between align-items-center w-100"
                >
                  <div class="flex-fill">{{ resource.title }}</div>
                  <div class="px-2 flex-shrink-1 flex-grow-0">
                    <button
                      class="btn btn-primary py-0 px-2 mx-1"
                      :class="{ 'semi-transparent no-hover': !resource.file }"
                      @click="viewResource(resource, 'file')"
                    >
                      <span class="badge bg-primary"> PDF </span>
                    </button>
                    <button
                      class="btn bg-disabled py-0 px-3 mx-1"
                      :class="{
                        'semi-transparent no-hover': !resource.youtube_link,
                      }"
                      @click="viewResource(resource, 'youtube')"
                    >
                      <img
                        style="height: 13px; width: auto"
                        src="@/assets/icons/youtube.png"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="currentView == 'assessments'">
      <div
        class="text-center bg-white border py-3 shadow-sm fst-italic text-muted small rounded"
        v-if="classAssessments.length == 0"
      >
        No assessment available
      </div>
      <div class="row g-3 mx-0" v-else>
        <div
          class="col-12 bg-white border rounded shadow-sm p-3 d-flex justify-content-between align-items-center flex-column flex-lg-row"
          v-for="assessment in classAssessments"
          :key="assessment._id"
        >
          <div class="mb-3 mb-lg-0 text-center text-lg-start">
            <div
              class="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center mb-2"
            >
              <span
                class="badge me-lg-2 mb-2 mb-lg-0"
                :class="`bg-${Helper.getLevelColor(
                  classLevel(assessment.class)
                )}`"
                v-if="assessment.class && classLevel(assessment.class)"
              >
                <small>{{ classLevel(assessment.class) }}</small>
              </span>
              <small class="fw-medium">
                {{ Helper.removeBreakLine(className(assessment.class)) }}
              </small>
            </div>
            <p class="mb-0">
              {{ assessment.title }}
            </p>
          </div>
          <div class="d-flex align-items-center ps-3">
            <button
              class="btn btn-sm text-light lh-sm me-2 text-nowrap"
              :class="
                assessmentMarks(assessment._id) !== null &&
                assessmentMarks(assessment._id) < 50
                  ? 'bg-purple-dark'
                  : 'bg-blue-dark'
              "
              v-if="assessmentMarks(assessment._id) < 50"
              @click="takeAssessment(assessment._id)"
            >
              <span
                v-if="
                  assessmentMarks(assessment._id) !== null &&
                  assessmentMarks(assessment._id) < 50
                "
              >
                Retake
              </span>
              <span v-else>Take</span>
              assessment
              <i class="fas fa-caret-right ms-2" />
            </button>
            <button
              @click="takeAssessment(assessment._id, true)"
              class="btn btn-sm btn-secondary text-light lh-sm me-2 text-nowrap"
              v-else
            >
              View Result
              <i class="fas fa-eye ms-2" />
            </button>
            <span
              class="badge border fw-normal fs-6"
              :class="
                assessmentMarks(assessment._id) !== null
                  ? assessmentMarks(assessment._id) >= 50
                    ? 'bg-success'
                    : 'bg-warning'
                  : 'bg-light text-muted'
              "
            >
              {{ assessmentMarks(assessment._id) || 0 }}%
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="currentView == 'report-cards'">
      <div class="table-responsive">
        <table class="table lh-sm table-admin table-hover w-100">
          <thead>
            <tr>
              <th style="width: 1%" class="pe-0">#</th>
              <th>Report Card</th>
              <th style="width: 1%" class="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(card, index) in reportCards"
              :key="card._id"
              @click="currentMedia = card.card"
            >
              <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
              <td class="text-nowrap text-truncate lh-sm">
                <small class="fw-medium text-muted">{{ card.title }}</small>
                <p class="mb-0">
                  {{
                    card.class ? Helper.removeBreakLine(card.class.name) : ""
                  }}
                </p>
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ Helper.formatDate(card.date) }}
              </td>
            </tr>
            <tr v-if="reportCards.length < 1">
              <td colspan="3" class="text-center fst-italic text-muted">
                No report card yet
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="currentView == 'certificates'">
      <div class="table-responsive">
        <table class="table lh-sm table-admin table-hover w-100">
          <thead>
            <tr>
              <th style="width: 1%" class="pe-0">#</th>
              <th>Certificate</th>
              <th style="width: 1%" class="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(cert, index) in certificates"
              :key="cert._id"
              @click="currentMedia = cert.card"
            >
              <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
              <td class="text-nowrap text-truncate lh-sm">
                {{ cert.title }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ Helper.formatDate(cert.date) }}
              </td>
            </tr>
            <tr v-if="certificates.length < 1">
              <td colspan="3" class="text-center fst-italic text-muted">
                No certificate yet
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      :mime-type="this.Helper.getMediaMimeType(currentMedia)"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import MediaViewer from "@/components/Modals/MediaViewer";
import Assessment from "@/components/Assessment";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: ["currentDependant"],
  components: {
    MediaViewer,
    Assessment,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      currentView: "details",
      currentMedia: null,
      classes: [],
      reportCards: [],
      certificates: [],
    };
  },
  watch: {
    currentDependant: {
      async handler() {
        if (this.currentDependant) {
          if (
            this.currentDependant.classes &&
            this.currentDependant.classes.length > 0
          ) {
            await this.getClasses();
          }

          if (
            this.currentDependant.dependant_report_cards &&
            this.currentDependant.dependant_report_cards.length > 0
          ) {
            await this.getReportCards();
          }

          if (
            this.currentDependant.dependant_certificates &&
            this.currentDependant.dependant_certificates.length > 0
          ) {
            await this.getCertificates();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    classAssessments() {
      const assessments = JSON.parse(JSON.stringify(this.classes))
        .map((cls) => cls.assessments)
        .flat()
        .filter((ca) => ca.status == "Published");

      return assessments.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    className() {
      return (classId) => {
        const cls = this.classes.find((cls) => cls._id == classId);

        if (cls) {
          return cls.name;
        } else {
          return null;
        }
      };
    },
    classLevel() {
      return (classId) => {
        const cls = this.classes.find((cls) => cls._id == classId);

        if (cls) {
          return cls.level;
        } else {
          return null;
        }
      };
    },
    classResources() {
      return (classId) => {
        const cls = this.classes.find((cls) => cls._id == classId);

        if (cls && cls.resources) {
          const resources = cls.resources.filter(
            (ca) => ca.status == "Published"
          );

          let groupedResources = [];

          resources.forEach((resource, index) => {
            const x = groupedResources.find((gr) => gr.title == resource.title);

            if (x) {
              if (resource.youtube_link) {
                x.youtube_link = resource.youtube_link;
              } else if (resource.file) {
                x.file = resource.file;
              }
            } else {
              groupedResources.push({
                _id: index,
                title: resource.title,
                youtube_link: resource.youtube_link
                  ? resource.youtube_link
                  : null,
                file: resource.file ? resource.file : null,
              });
            }
          });

          return groupedResources.sort((a, b) => {
            const aTitleSplit = a.title.split(" ");
            const bTitleSplit = b.title.split(" ");

            const lastNumberA = parseInt(aTitleSplit[aTitleSplit.length - 1]);
            const lastNumberB = parseInt(bTitleSplit[bTitleSplit.length - 1]);

            return lastNumberA - lastNumberB;
          });
        } else {
          return [];
        }
      };
    },
    assessmentMarks() {
      return (assessmentId) => {
        const assessment = this.currentDependant.dependant_assessments
          ? this.currentDependant.dependant_assessments.find(
              (da) => da.assessment == assessmentId
            )
          : null;

        return assessment ? assessment.marks || 0 : null;
      };
    },
  },
  methods: {
    viewResource(resource, type) {
      if (!resource) return;

      if (type == "youtube" && resource.youtube_link) {
        // window.open(resource.youtube_link, "_blank");

        this.currentMedia = {
          url: "https://www.youtube.com/embed/".concat(
            resource.youtube_link.replace("https://youtu.be/", "")
          ),
          mime: "youtube",
        };
      } else if (type == "file" && resource.file) {
        this.currentMedia = resource.file;
      }
    },
    changeView(view) {
      if (view !== "details" && this.$route.query.view !== view) {
        this.$router.replace({ query: { view: view } });
      } else if (view == "details" && this.$route.query.view) {
        this.$router.replace({ query: {} });
      }
    },
    closeAssessment(e) {
      window.scrollTo(0, 0);

      if (e && e.data) {
        let updatedCurrentDependant = JSON.parse(
          JSON.stringify(this.currentDependant)
        );

        let assessmentIndex =
          updatedCurrentDependant.dependant_assessments.findIndex(
            (da) => da.assessment == e.data.assessment
          );

        if (assessmentIndex > -1) {
          updatedCurrentDependant.dependant_assessments[assessmentIndex] =
            e.data;
        } else {
          updatedCurrentDependant.dependant_assessments.push(e.data);
        }

        this.$emit("update", updatedCurrentDependant);
      }

      this.currentView = "assessments";
    },
    takeAssessment(assessmentId, readOnly) {
      const assessment = this.classAssessments.find(
        (ca) => ca._id == assessmentId
      );

      if (!assessmentId || !assessment) {
        return;
      }

      const dependantAssessment = this.currentDependant.dependant_assessments
        ? this.currentDependant.dependant_assessments.find(
            (da) => da.assessment == assessmentId
          )
        : null;

      if (readOnly) {
        this.currentView = JSON.stringify({
          id: assessmentId,
          dependantAssessment: dependantAssessment
            ? dependantAssessment._id
            : null,
          assessmentResult: dependantAssessment
            ? dependantAssessment.answers
            : null,
          assessmentMarks: dependantAssessment
            ? dependantAssessment.marks
            : null,
        });
      } else {
        Swal.fire({
          title: `<h5 class="mb-0">${
            dependantAssessment ? "Retake" : "Take"
          } this assessment?</h5>`,
          html: `
          <p class="small bg-light rounded border shadow-sm p-3 text-dark">${assessment.title}</p>
          <p class="mb-0"></p>
        `,
          icon: "question",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            this.currentView = JSON.stringify({
              id: assessmentId,
              dependantAssessment: dependantAssessment
                ? dependantAssessment._id
                : null,
            });
          }
        });
      }
    },
    async getClasses() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `classes?_limit=-1&status_ne=Deleted&dependants.id=${this.currentDependant._id}`
          );

          this.classes = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
    async getReportCards() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          let url = `dependant-report-cards?_limit=-1&dependant=${this.currentDependant._id}&_sort=date:DESC`;

          const { data } = await this.API.get(url);

          this.reportCards = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
    async getCertificates() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          let url = `dependant-certificates?_limit=-1&dependant=${this.currentDependant._id}&_sort=date:DESC`;

          const { data } = await this.API.get(url);

          this.certificates = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.view) {
      this.currentView = this.$route.query.view;
    }
  },
};
</script>